import React, { useState } from 'react'


// import rightarrow from '../../../../Assets/Img/rightarrow.svg'
import fazalpic from '../../../../Assets/Img/fazalpic.svg'
import Navbar from '../../Navbar/Navbar'
import rectangle from '../../../../Assets/Img/rectangle.svg'

export default function FazalProfile() {


  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="faizanprofilemaindiv">

        <div className='mainflex'>



          <div className='maincontent'>


            <h1>Fazal Rehman</h1>
            <h2>UX UI designer</h2>
            <h3>5 Years</h3>

            <h4>Skillset:</h4>

            <div className='mainskillsetdiv'>

              <div className='skilldiv'>
                <h5>Adobe Photoshop</h5>
              </div>

              <div className='skilldiv'>
                <h5>Adobe Illustrator</h5>
              </div>

              <div className='skilldiv'>
                <h5>Graphic Design</h5>
              </div>

              <div className='skilldiv'>
                <button onClick={openModal} >See All</button>

                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>

                      <div className="theskills">
                        <h1>Fazal Skillset</h1>

                        <div className='marquee-container skillsmaindivslide'>

                          <div className='allskillsdiv'>
                            <p>Adobe Photoshop</p>
                            <p>Graphic Design</p>
                            <p>Video Editing</p>

                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>Adobe Illustrator</p>
                              <p>User Interface Design</p>


                            </div>

                          </div>

                        </div>
                        {/* 
                        <div className="bottomlinehr">
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>


            </div>


            <br /><br />


            <p id='maincontentparagraph'>I am designing visually compelling interfaces that prioritize user experience.
            </p>

          </div>



          <div className='faizanimg'>
            <img src={fazalpic} alt="" />
          </div>

        </div>




        <div className="skillexperiencediv">

          <div className="processmaindiv">
            <h1 className='experienceheading' >Experience</h1>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Creative Lead | UI Designer</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Creative Lead | UI Designerr</h2>
                <p>
                  Dataspecc
                  <br />
                  Full-time
                  <br />
                  Feb 2020 - Present · 4 yrs 1 mo
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Social Media Designer </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2> UI Designer</h2>
                <p>
                  ESirius.io
                  <br />
                  Full-time <br />

                  Aug 2020 - Jan 2022 · 1 yr 6 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Social Media Designer </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>UI DesignerSenior Software Engineer</h2>
                <p>
                  ESirius.io
                  <br />
                  Full-time <br />
                  Aug 2020 - Jan 2022 · 1 yr 6 mos <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>



          </div>
        </div>




        <div className='navbarposi'>
          <Navbar />
        </div>
      </div>

    </div>
  )
}