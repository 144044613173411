
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Ourtalent.css';
import { Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

import faizanpic from '../../../Assets/Img/faizanpic.svg'
import juanidpic from '../../../Assets/Img/juanidpic.svg';
import ahmedpic from '../../../Assets/Img/ahmedpic.svg';
import syedbanooripic from '../../../Assets/Img/syedbanooripic.svg'
import fazalpic from '../../../Assets/Img/fazalpic.svg'
// import ziapic from '../../../Assets/Img/zaipic.svg'
import rizwanpic from '../../../Assets/Img/rizwanpic.svg'
// import reeyanpic from '../../../Assets/Img/reeyanpic.svg';


export default function App() {
  const [swiperRef, setSwiperRef] = useState(null);
  console.log(swiperRef);
  return (
    <>
      <div  id='Our_Talent' data-aos="fade-up" data-aos-duration="1500" className="talentdiv">
        <h1>OUR TALENT</h1>
      </div>

      <div data-aos="zoom-in" data-aos-duration="1000" className="maindivoftalentswiper">
        <Swiper
          onSwiper={setSwiperRef}
          spaceBetween={30}
          loop={true} // Enable looping
          pagination={{
            type: 'fraction',
          }}
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          breakpoints={{
            500: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          slideActiveClass="swiper-slide-active-custom"
          slideNextClass="swiper-slide-next-custom"
          slidePrevClass="swiper-slide-prev-custom"
        >
          <SwiperSlide>
            <Link to='/Faizan'>
              <div className="swiperimage">
                <img src={faizanpic} alt="" />
                <div className="imagebelow">
                  <div>Faizan Khan</div>
                  <div>Full Stack / MERN Stack Developer</div>
                  <div>6 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to='/Junaid'>
              <div className="swiperimage">
                <img src={juanidpic} alt="" />
                <div className="imagebelow">
                  <div>Junaid Durrani</div>
                  <div>Application Developer</div>
                  <div>5 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to='/Ahmed'>
              <div className="swiperimage">
                <img src={ahmedpic} alt="" />
                <div className="imagebelow">
                  <div>Ahmed Hamza</div>
                  <div>Creative UI/UX Designer</div>
                  <div>6 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>

          {/* <SwiperSlide>
            <Link to='/Reeyan'>
              <div className="swiperimage">
                <img src={reeyanpic} alt="" />
                <div className="imagebelow">
                  <div>Reeyan Khan</div>
                  <div>Developer</div>
                  <div>1.5 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide> */}

          <SwiperSlide>
            <Link to='/rizwan'>
              <div className="swiperimage">
                <img src={rizwanpic} alt="" />
                <div className="imagebelow">
                  <div>Rizwan Sadiq</div>
                  <div>Video Editor</div>
                  <div>8 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to='/fazal'>
              <div className="swiperimage">
                <img src={fazalpic} alt="" />
                <div className="imagebelow">
                  <div>Fazal Rehman</div>
                  <div>UX UI designer</div>
                  <div>5 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to='/syedbanoori'>
              <div className="swiperimage">
                <img src={syedbanooripic} alt="" />
                <div className="imagebelow">
                  <div>Syed Banoori</div>
                  <div>UX UI designer</div>
                  <div>5 Years</div>
                </div>
              </div>
            </Link>
          </SwiperSlide>

      
       

        
         
        </Swiper>
      </div>
    </>
  );
}
