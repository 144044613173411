import React, { useState } from 'react'
import './Juanid.css'

// import rightarrow from '../../../../Assets/Img/rightarrow.svg'
import juanidpic from '../../../../Assets/Img/juanidpic.svg'
import Navbar from '../../Navbar/Navbar'

import rectangle from '../../../../Assets/Img/rectangle.svg'

export default function Juanid() {



  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div>
      <div className="faizanprofilemaindiv">

        <div className='mainflex'>



          <div className='maincontent'>


            <h1>Junaid Durrani</h1>
            <h2>Application Developer</h2>
            <h3>5 Years</h3>
            <h4>Skillset:</h4>

            <div className='mainskillsetdiv'>

              <div className='skilldiv'>
                <h5>Flutter</h5>
              </div>

              <div className='skilldiv'>
                <h5>React Native</h5>
              </div>

              <div className='skilldiv'>
                <h5>Android</h5>
              </div>

              <div className='skilldiv'>
                <button onClick={openModal} >See All</button>

                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>

                      <div className="theskills">
                        <h1>Junaid Durrani Skillset</h1>

                        <div className='marquee-container skillsmaindivslide'>

                          <div className='allskillsdiv'>
                            <p>Dart</p>
                            <p>Node.js</p>
                            <p>Firebase</p>
                            <p>MongoDB</p>
                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>(CSS)</p>
                              <p>CodeIgniter</p>
                              <p>Android Development</p>
                              <p>Flutter</p>
                            </div>

                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container '>

                            <div className='allskillsdiv'>
                              <p>PHP</p>
                              <p>Bootstrap</p>
                              <p>HTML5</p>
                              <p>iOS</p>
                              <p>Git</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>


            </div>


            <br /><br />


            <p id='maincontentparagraph'>Experienced Application Developer with a proven track record in designing, Proficient in leveraging a diverse set of technologies to create efficient, scalable, and user-centric applications.Skilled in collaborating with cross-functional teams to understand business requirements and translate them into effective software solutions. Adept at staying updated on the latest industry trends and emerging technologies to ensure the integration of innovative features and functionalities.</p>

          </div>



          <div className='faizanimg'>
            <img src={juanidpic} alt="" />
          </div>

        </div>

        {/* the experience div is here */}
        <div className="skillexperiencediv">

          <div className="processmaindiv">
            <h1 className='experienceheading' >Experience</h1>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>PTCL</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Manager Application Development</h2>
                <p>
                  PTCL.Official
                  <br />
                  Full-time
                  <br />
                  Oct 2023 - Present · 5 mos
                  <br />
                  Islāmābād, Pakistan
                  <br />
                  On-site
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Zong </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2> Software Engineer</h2>
                <p>
                  Zong CMPak Ltd
                  <br />
                  Full-time
                  <br />
                  May 2020 - Oct 2023 · 3 yrs 6 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Sigma Digital Solutions</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Mobile Application Developer</h2>
                <p>
                  Sigma Digital Solutions
                  <br />
                  Full-time
                  <br />
                  Oct 2018 - Apr 2020 · 1 yr 7 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>



          </div>
        </div>
        {/* the experience div is here */}

        <div className='navbarposi'>
          <Navbar />
        </div>
      </div>

    </div>
  )
}
