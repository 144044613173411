import React from 'react'
import './Whyus.css'

import way1pic from '../../../Assets/Img/way1.svg';
import way2pic from '../../../Assets/Img/way2.svg';
import way3pic from '../../../Assets/Img/way4.svg';

export default function Whyus() {
    return (
        <div>
            <div  className="whyusmaindiv">

                <div data-aos="fade-up"  data-aos-duration="1000">
                <h1 >Why Collab Ways</h1>
                </div>

                <div data-aos="zoom-in"   data-aos-duration="1500"  className='waysimgdiv'>

                    <div  className='way1div'>
                        <img src={way1pic} alt="way1pic" />

                        <div className='way1text'>
                            <h2>Work <br /> Directly</h2>
                            <p>Our clients benefit from the ability to directly engage with our elite resources, fostering a seamless and efficient collaboration designed to meet the unique requirements of each project.</p>
                        </div>
                    </div>

                    {/* 2 card */}
                    <div className='way1div'>
                        <img src={way2pic} alt="way1pic" />

                        <div className='way1text'>
                            <h2>Pre Vetted <br />
                                Talent Pool</h2>
                            <p>Pre-vetted talent pool refers to a group of individuals who have undergone an initial screening or evaluation process to ensure they meet certain qualifications or criteria before being considered for specific opportunities or roles.</p>
                        </div>

                    </div>

                    {/* 3 card */}
                    <div className='way1div'>
                        <img src={way3pic} alt="way1pic" />

                        <div className='way1text'>
                            <h2>5 year of <br /> experience</h2>
                            <p> As a testament to our dedication to excellence, we are pleased to introduce, each member possessing a minimum of five years of substantial experience in their specialized domains.</p>
                        </div>
                    </div>


                </div>




            </div>
        </div>
    )
}
