import React, { useState } from 'react'
import './Navbar.css'


import logo from '../../../Assets/Img/logo.svg'

import { Icon } from '@iconify/react';

export default function Navbar() {

  const [toogle, setToogle] = useState(false)


  const tooglebtn = () => {
    setToogle(!toogle);
  }

  return (
    <div>
      {/* navbar */}


      <div className="collwaysnavbar">

        <div className='logodiv'>
          <img className='collwaysimgnavbar' src={logo} alt="" />
          <h3>Collab Ways</h3>

        </div>
        <ul className={toogle ? 'newuflex' : 'uflex'}  >
          <li><a href="/">Home</a></li>
          <li><a href="#Our_Talent">Our Talent</a></li>
          <li><a href="#Our_Clients">Our Clients</a></li>
          <li><a href="#letstart">Contact us</a></li>
          <li><a id='hiredtext' target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/1a5OPJvKKXQZgjkM178KCtE2pFCMUYTaNNmHsXRBOO2E/viewform?edit_requested=true">Get Hired</a></li>
          <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSesYUmjlvNRXojr-SY7Gkc1mioSZq-gzEpxvNJojP_P4utysA/viewform">
            <button>Recruit Top Talent</button>
          </a>
        </ul>

        <span onClick={tooglebtn} className='menubaricon'><Icon icon="ic:outline-menu" /></span>




      </div>
    </div>
  )
}
