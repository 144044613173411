import React, { useState } from 'react'


// import rightarrow from '../../../../Assets/Img/rightarrow.svg'
import syedbanooripic from '../../../../Assets/Img/syedbanooripic.svg'
import Navbar from '../../Navbar/Navbar'
import rectangle from '../../../../Assets/Img/rectangle.svg'

export default function SyedBanoori() {

  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div>
      <div className="faizanprofilemaindiv">

        <div className='mainflex'>



          <div className='maincontent'>


            <h1>Syed Banoori</h1>
            <h2>UX UI designer</h2>
            <h3>5 Years</h3>
            <h4>Skillset:</h4>

            <div className='mainskillsetdiv'>

              <div className='skilldiv'>
                <h5>Start-ups</h5>
              </div>

              <div className='skilldiv'>
                <h5>Product Design</h5>
              </div>

              <div className='skilldiv'>
                <h5>Adobe Creative Suite</h5>
              </div>

              <div className='skilldiv'>
                <button onClick={openModal} >See All</button>

                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>

                      <div className="theskills">
                        <h1>Ahmad Banoori Skillset</h1>

                        <div className='marquee-container skillsmaindivslide'>

                          <div className='allskillsdiv'>
                            <p>Start-ups</p>
                            <p>Product Design</p>
                            <p>Adobe Creative Suite</p>

                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>Interaction Design</p>
                              <p>Android</p>
                              <p>Oral Communication</p>



                            </div>

                          </div>

                        </div>

                        <div className="bottomlinehr">
                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>Interfaces</p>
                              <p>Software Design</p>
                              <p>Design Management</p>



                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>


            </div>

            <br /><br />

            <p id='maincontentparagraph'>I am designing visually compelling interfaces that prioritize user experience.
            </p>

          </div>



          <div className='faizanimg'>
            <img src={syedbanooripic} alt="" />
          </div>

        </div>



        <div className="skillexperiencediv">

          <div className="processmaindiv">
            <h1 className='experienceheading' >Experience</h1>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>User Experiance designer</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>User Experiance designer</h2>
                <p>
                  Hashverse Solutions
                  <br />
                  Full-time
                  <br />
                  Mar 2023 - Present · 1 yr
                  <br />
                  Islāmābād, Pakistan · Remote
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Senior User Interface Designer</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Senior User Interface Designer</h2>
                <p>
                  Reactive Space
                  <br />
                  Full-time <br />

                  Oct 2022 - Feb 2023 · 5 mos
                  <br />
                  Islāmābād, Pakistan · Hybrid
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>User Interface Designer </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>User Interface Designer</h2>
                <p>
                  Meta Warriors Club
                  <br />
                  Full-time <br />
                  Nov 2021 - Sep 2022 · 11 mos <br />
                  Bahria Springs North, Phase 7 Rawalpindi · Remote
                </p>
              </div>
            </div>



          </div>
        </div>




        <div className='navbarposi'>
          <Navbar />
        </div>
      </div>

    </div>
  )
}