import React from 'react'
import './Process.css'

import rectangle from '../../../Assets/Img/rectangle.svg'

export default function Process() {
    return (
        <div>
            <div data-aos="fade-up" data-aos-duration="1500" className="processmaindiv">
                <h1 >Our Vetting Process</h1>


                <div className="mainflexdivofsteps">



                    <div className='parentdivwidth'>
                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 01</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Resume and Application Review</h2>
                                <p>We initiate the journey by conducting a thorough review of resumes and applications, aligning them with our defined job requirements. This initial step allows us to make an assessment of qualifications and suitability for the role.</p>
                            </div>
                        </div>


                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 02</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Pre-screening Interview:</h2>
                                <p>Delve deeper into the candidate's professional journey through a pre-screening interview. This stage involves exploring their experiences, motivations, and assessing cultural fit within our dynamic team.</p>
                            </div>
                        </div>


                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 03</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Technical Assessment:</h2>
                                <p>Candidates showcase their technical prowess through a comprehensive assessment tailored to evaluate skills and problem-solving abilities relevant to the specific role they are pursuing.</p>
                            </div>
                        </div>


                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 04</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Hands-On Assignment:</h2>
                                <p>Practical skills are spotlighted with a hands-on assignment that offers a real-world glimpse into the candidate's capabilities and creativity within the context of industry challenges.</p>
                            </div>
                        </div>

                    </div>

                    {/* THe second area */}


                    <div className='parentdivwidth'>
                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 05</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Behavioral Interview:</h2>
                                <p>Uncover soft skills and behavioral attributes in a focused interview designed to assess alignment with company values and the collaborative nature of the team.</p>
                            </div>
                        </div>


                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 06</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Technical Interview:</h2>
                                <p>Delve into technical intricacies with a specialized technical interview, allowing us to assess expertise and proficiency in key areas essential for success in the chosen role.</p>
                            </div>
                        </div>



                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 07</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Reference Checks:</h2>
                                <p>Validation of professional history and achievements is conducted through comprehensive reference checks, ensuring the accuracy and reliability of shared information.</p>
                            </div>
                        </div>



                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 08</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Background Check:</h2>
                                <p>Prioritizing security and integrity, our background check process confirms qualifications, providing an additional layer of assurance for our clients.</p>
                            </div>
                        </div>


                    </div>


                    {/* THe Third area */}


                    <div className='parentdivwidth'>
                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 09</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Cultural Fit Assessment:</h2>
                                <p>Explore the alignment between candidate values and company culture to ensure a harmonious fit within the dynamic and collaborative work environment.</p>
                            </div>
                        </div>



                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 10</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Personality Assessment:</h2>
                                <p>Understand unique personality traits to ensure a holistic understanding of the candidate's professional profile and how it complements the team dynamics.</p>
                            </div>
                        </div>

                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 11</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Scenario-Based Questions:</h2>
                                <p>Navigate real-world scenarios through targeted questions assessing problem-solving skills and decision-making abilities in the context of industry challenges.</p>
                            </div>
                        </div>


                        <div className="parentdivofstep">
                            <div className='rectanglepicandstepdiv'>
                                <img src={rectangle} alt="" />
                                <h3>Step 12</h3>
                            </div>

                            <div className="stepheadingdiv">
                                <h2>Final Interview:</h2>
                                <p>The pinnacle of our vetting process, the final interview provides an opportunity for both parties to align on expectations and ensure a mutually beneficial partnership.</p>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </div>
    )
}
