
import React from 'react'
import './Guarantee.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
// import { Pagination } from 'swiper/modules';

export default function Guarantee() {

    const guarantecardData = [
        {
            title: 'Security and Confidentiality',
            description: 'Your data and information are secure with us. We uphold the highest standards of confidentiality and implement robust security measures.',
        },
        {
            title: 'Customer Support',
            description: 'Our customer support is always ready to assist you. Any concerns or questions will be promptly addressed to ensure your peace of mind.',
        },
        {
            title: 'Transparent Pricing',
            description: 'No hidden fees or surprises. Our pricing is transparent and straightforward, ensuring you know exactly what to expect.',
        },
        {
            title: 'Quality Assurance',
            description: 'Our commitment to quality is unwavering. You can trust that our service will meet or exceed your expectations, every time.',
        },
        {
            title: 'Continuous Improvement',
            description: 'We are committed to evolving and improving our services. Your feedback is valued, and we strive for continuous enhancement to better serve you.',
        },

        // Add more data objects as needed
    ];

    return (
        <div>



            <div className="guaranteeparentdiv">
                <div>
                    <h1>
                        <div data-aos="fade-up" data-aos-duration="1000"> Your</div>
                        <div data-aos="fade-up" data-aos-duration="1200">Best Digital</div>
                        <div data-aos="fade-up" data-aos-duration="1500">  Partner</div>
                    </h1>
                </div>

                <div className="maindivofg">

                    <div data-aos="zoom-in" data-aos-duration="1000" className="guarentecardparent">

                        <Swiper slidesPerView={4} grabCursor={true} loop={true} spaceBetween={0} className="mySwiper" >
                            {guarantecardData.map((data, index) => (
                                <SwiperSlide key={index} className='swiperslidediv'>
                                    <div className="gurantecards">
                                        <h2>{data.title}</h2>
                                        <p>{data.description}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>





                    </div>
                </div>

            </div>
        </div>
    )
}