import { useState } from 'react';
// import { BsPlay, BsPause } from 'react-icons/bs';
import './Ourclient.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
// import clientvideo from '../../../Assets/videos/video.mp4'
// import clientvideo2 from '../../../Assets/videos/video.mp4'
// import { Pagination } from 'swiper/modules';


// import brandoralogo from '../../../Assets/Img/brandoralogo.svg'
import techeaselogo from '../../../Assets/Img/techeaselogo.svg'
import clientimgfrom from '../../../Assets/Img/clientlogo.svg'
import kashifpic2 from '../../../Assets/Img/kashifpic2.svg'
import felixpic from '../../../Assets/Img/felixpic.svg'
import felixpic2 from '../../../Assets/Img/felixpic2.svg'
import sanalogo from '../../../Assets/Img/sanalogo.svg'
// import brandoralogo from '../../../Assets/Img/brandoralogo.svg'
import crossworklogo from '../../../Assets/Img/crossworklogo.svg'
import muzeslogo from '../../../Assets/Img/muzeslogo.svg'
import crossworkpman from '../../../Assets/Img/crossworkpic.svg'
import crossworkman2 from '../../../Assets/Img/crossworkpic2.svg'
import muzesman from '../../../Assets/Img/muzesmanpic.svg'
import muzesman2 from '../../../Assets/Img/muzesmanpic2.svg'
// import { Icon } from '@iconify/react';

export default function Ourclient() {
  // const [paused, setPaused] = useState(false);
  // const [paused2, setPaused2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  // const [showPopup3, setShowPopup3] = useState(false);
  const [showPopup4, setShowPopup4] = useState(false);
  const [showPopup5, setShowPopup5] = useState(false);


  const techease = "I wish to convey my deep appreciation for the exceptional resources and services provided by Collabways. The innovative solutions and unwavering support have proven invaluable to Techease, reflecting the exemplary standard of professionalism upheld by your consultancy. I commend your team's commitment to excellence and anticipate continued success through our collaborative endeavors.";
  const sanatext = "I am pleased to express my appreciation for Collabways' exemplary resources and services. As the CEO of Sana, I have witnessed the positive impact of their innovative solutions on our business operations. Collabways' commitment to excellence makes them a valuable partner for companies aspiring to achieve optimal efficiency.";
  // const brandoratext = "I extend my formal appreciation for the exemplary resources and services provided by your esteemed organization. As the Tech Lead at Brandora Design Agency, I have observed the proFounder impact of Collabways' solutions on our operational efficiency. The unwavering commitment to excellence and professionalism demonstrated by your team has been instrumental in advancing our projects. I look forward to a continued collaboration marked by continued success."
  const Crosswork = "I am compelled to express my gratitude for the exceptional resources and services provided by Collabways. As the CEO of Crosswork, I've Founder their collaborative tools to be instrumental in streamlining our operations. Collabways' commitment to excellence and innovative solutions make them an invaluable partner for businesses seeking efficiency and success."
  const muzestext = "I would like to convey my formal appreciation for the exceptional resources and services delivered by Collabways. In my capacity as the CEO of Muzes, I have Founder their collaborative tools to be instrumental in optimizing our operations. Collabways' unwavering commitment to excellence and innovative solutions position them as a valuable partner for businesses seeking efficiency and success.";

  // const videoRef = useRef(null);
  // const videoRef2 = useRef(null);

  // const handleClick = () => {
  //   setPaused(!paused);
  //   if (videoRef.current) {
  //     if (paused) {
  //       videoRef.current.play();
  //     } else {
  //       videoRef.current.pause();
  //     }
  //   }
  // };

  // const handleClick2 = () => {
  //   setPaused2(!paused2);
  //   if (videoRef2.current) {
  //     if (paused2) {
  //       videoRef2.current.play();
  //     } else {
  //       videoRef2.current.pause();
  //     }
  //   }
  // };


  return (
    <div>
      <div  id='Our_Clients' className="ourclientmaindiv">

        <div data-aos="fade-up" data-aos-duration="1500">
          <h1>Our Clients</h1>
        </div>

        {/* <div className='brandoralogodiv'>
          <img src={brandoralogo} alt="" />
        </div> */}

        <div data-aos="zoom-in" data-aos-duration="1000" className="clientmaindivflex">

          <Swiper
            slidesPerView={3}
            loop={true}
            spaceBetween={0}
            className="mySwiper"
          >

            {/* The fst slider */}
            <SwiperSlide>
              <div onClick={() => setShowPopup(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img src={techeaselogo} alt="" />
                  <p>
                    I wish to convey my
                    <br /> deep
                    appreciation
                    for<br /> the
                    exceptional resources
                    and <br /> services
                    <br />  provided by
                    {' '}
                    <span onClick={() => setShowPopup(true)} className="read-more-link">Read More</span>
                  </p>

                  <h2>Kashif Taj</h2>
                  <span>CEO and Founder</span>
                  <br />
                  <small>Techease</small>
                </div>

                <div className="rightsideofclientdiv">
                  <img src={clientimgfrom} alt="" />
                </div>
              </div>


            </SwiperSlide>
            {/* Popup  1*/}
            {showPopup && (
              <div className="modal">
                <div className="modal-content">
                  <span onClick={() => setShowPopup(false)} className="close">&times;</span>

                  <div id='modaldivcenter'>

                    <div className="clientimgdivmodal">

                      <div className='leftsideofclientdiv'>
                        <img src={techeaselogo} alt="" />
                        <p>{techease}</p>
                        <h2>Kashif Taj</h2>
                        <span>CEO and Founder </span>
                        <br />
                        <small>Techease</small>
                      </div>

                      <div className="rightsideofclientdiv">
                        <img src={kashifpic2} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* the first slider is end */}

            {/* the second slider is here */}
            {/* the video slider */}

            <SwiperSlide>

              <div onClick={() => setShowPopup2(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img id='sanapic' src={sanalogo} alt="" />
                  <p>I am pleased to express
                    <br />
                    my appreciation for
                    <br />
                    Collabways' exemplary
                    <br /> resources and services.
                    <br /> As the CEO <br />
                    of Sana,
                    {' '}
                    <span onClick={() => setShowPopup2(true)} className="read-more-link">Read More</span>
                    <br />  </p>
                  <h2>Felix Ayeni</h2>
                  <span>CEO and Founder </span>
                  <br />
                  <small>Sana</small>
                </div>


                {/* <div className="rightsideofclientdiv" onClick={handleClick2}>
                  <video autoPlay muted loop ref={videoRef2} src={clientvideo2}></video>
                  <div className='playicon'>
                    {paused2 ? <BsPlay /> : <BsPause />}
                  </div>
                </div> */}

                <div className="rightsideofclientdiv">
                  <img src={felixpic} alt="" />
                </div>

              </div>
            </SwiperSlide>
            {/* Popup  2*/}
            {showPopup2 && (
              <div className="modal">
                <div className="modal-content">
                  <span onClick={() => setShowPopup2(false)} className="close">&times;</span>

                  <div id='modaldivcenter'>

                    <div className="clientimgdivmodal">

                      <div className='leftsideofclientdiv'>
                        <img src={sanalogo} alt="" />
                        <p>{sanatext}</p>
                        <h2>Felix Ayeni</h2>
                        <span>CEO and Founder </span>
                        <br />
                        <small>Sana</small>
                      </div>

                      <div className="rightsideofclientdiv">
                        <img src={felixpic2} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* the second slider is here end */}

            {/* the third slider is start */}

            {/* <SwiperSlide>

              <div onClick={() => setShowPopup3(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img src={brandoralogo} alt="" />
                  <p>I extend my formal appreciation

                    for  the<br /> exemplary resources
                    <br />
                    and services
                    <br /> provided by
                    <br />your

                    {' '}
                    <span onClick={() => setShowPopup3(true)} className="read-more-link">Read More</span>
                    <br />  </p>

                  <h2 id='heading2slidertext'>Masab Jamal</h2>
                  <span>CEO and Founder </span>
                  <br />
                  <small>Brandora</small>
                </div>

                <div className="rightsideofclientdiv">
                  <img src={clientimgfrom} alt="" />
                </div>

              </div>
            </SwiperSlide>

          
            {showPopup3 && (
              <div className="modal">
                <div className="modal-content">
                  <span onClick={() => setShowPopup3(false)} className="close">&times;</span>

                  <div id='modaldivcenter'>

                    <div className="clientimgdivmodal">

                      <div className='leftsideofclientdiv'>
                        <img src={brandoralogo} alt="" />
                        <p>{brandoratext}</p>
                        <h2>Masab Jamal</h2>
                        <span>CEO and Founder </span>
                        <br />
                        <small>Brandora</small>
                      </div>

                      <div className="rightsideofclientdiv">
                        <img src={crossworkman2} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )} */}

            {/* Four slider is start */}

            <SwiperSlide>

              <div onClick={() => setShowPopup4(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img src={crossworklogo} alt="" />
                  <p>I am compelled to <br />
                    express my gratitude for
                    the exceptional resources and  services
                    provided  <br />

                    Crosswork,
                    {' '}
                    <span onClick={() => setShowPopup4(true)} className="read-more-link">Read More</span>
                    <br />  </p>

                  <h2>Steve</h2>
                  <span>CEO and Founder </span>
                  <br />
                  <small>Crosswork</small>
                </div>


                <div className="rightsideofclientdiv">
                  <img src={crossworkpman} alt="" />
                </div>

              </div>
            </SwiperSlide>

            {/* Popup  4*/}
            {showPopup4 && (
              <div className="modal">
                <div className="modal-content">
                  <span onClick={() => setShowPopup4(false)} className="close">&times;</span>

                  <div id='modaldivcenter'>

                    <div className="clientimgdivmodal">

                      <div className='leftsideofclientdiv'>
                        <img src={crossworklogo} alt="" />
                        <p>{Crosswork}</p>
                        <h2>Steve</h2>
                        <span>CEO and Founder </span>
                        <br />
                        <small>Crosswork</small>
                      </div>

                      <div className="rightsideofclientdiv">
                        <img src={crossworkman2} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}


            {/* Fith slider is here */}
            <SwiperSlide>

              <div onClick={() => setShowPopup5(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img id='sanapic' src={muzeslogo} alt="" />
                  <p>I would like to convey
                    <br />
                    my formal appreciation
                    <br />
                    for the exceptional <br />
                    resources and services
                    <br />
                    delivered by
                    <br />
                    Collabways.
                    <span onClick={() => setShowPopup5(true)} className="read-more-link">Read More</span>
                    <br />  </p>
                  <h2>Seun</h2>
                  <span>CEO and Founder </span>
                  <br />
                  <small>Muzes</small>
                </div>

                <div className="rightsideofclientdiv">
                  <img src={muzesman} alt="" />
                </div>

              </div>
            </SwiperSlide>

            {/* Popup  5*/}
            {showPopup5 && (
              <div className="modal">
                <div className="modal-content">
                  <span onClick={() => setShowPopup5(false)} className="close">&times;</span>

                  <div id='modaldivcenter'>

                    <div className="clientimgdivmodal">

                      <div className='leftsideofclientdiv'>
                        <img src={muzeslogo} alt="" />
                        <p>{muzestext}</p>
                        <h2>Seun Akinniranye</h2>
                        <span>CEO and Founder </span>
                        <br />
                        <small>Muzes</small>
                      </div>

                      <div className="rightsideofclientdiv">
                        <img src={muzesman2} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* The fst slider */}
            <SwiperSlide>
              <div onClick={() => setShowPopup(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img src={techeaselogo} alt="" />
                  <p>
                    I wish to convey my
                    <br /> deep
                    appreciation
                    for<br /> the
                    exceptional resources
                    and <br /> services
                    <br />  provided by
                    {' '}
                    <span onClick={() => setShowPopup(true)} className="read-more-link">Read More</span>
                  </p>

                  <h2>Kashif Taj</h2>
                  <span>CEO and Founder</span>
                  <br />
                  <small>Techease</small>
                </div>

                <div className="rightsideofclientdiv">
                  <img src={clientimgfrom} alt="" />
                </div>
              </div>


            </SwiperSlide>


            {/* the second slider is here */}


            <SwiperSlide>

              <div onClick={() => setShowPopup2(true)} className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img id='sanapic' src={sanalogo} alt="" />
                  <p>I am pleased to express
                    <br />
                    my appreciation for
                    <br />
                    Collabways' exemplary
                    <br /> resources and services.
                    <br /> As the CEO <br />
                    of Sana,
                    {' '}
                    <span onClick={() => setShowPopup2(true)} className="read-more-link">Read More</span>
                    <br />  </p>
                  <h2>Felix Ayeni</h2>
                  <span>CEO and Founder </span>
                  <br />
                  <small>Sana</small>
                </div>


                {/* <div className="rightsideofclientdiv" onClick={handleClick2}>
                  <video autoPlay muted loop ref={videoRef2} src={clientvideo2}></video>
                  <div className='playicon'>
                    {paused2 ? <BsPlay /> : <BsPause />}
                  </div>
                </div> */}

                <div className="rightsideofclientdiv">
                  <img src={felixpic} alt="" />
                </div>

              </div>
            </SwiperSlide>
            {/* Popup  2*/}
            {showPopup2 && (
              <div className="modal">
                <div className="modal-content">
                  <span onClick={() => setShowPopup2(false)} className="close">&times;</span>

                  <div id='modaldivcenter'>

                    <div className="clientimgdivmodal">

                      <div className='leftsideofclientdiv'>
                        <img src={sanalogo} alt="" />
                        <p>{sanatext}</p>
                        <h2>Felix Ayeni</h2>
                        <span>CEO and Founder </span>
                        <br />
                        <small>Sana</small>
                      </div>

                      <div className="rightsideofclientdiv">
                        <img src={felixpic2} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* the second slider is here end */}
{/* 
            <SwiperSlide>

              <div className="clientimgdiv">

                <div className='leftsideofclientdiv'>
                  <img src={techeaselogo} alt="" />
                  <p>Your data and information are secure with us. We uphold the highest standards of confidentiality and </p>
                  <h2>Kashif Taj</h2>
                  <span>CEO and Founder </span>
                  <br />
                  <small>Techease</small>
                </div>


                <div className="rightsideofclientdiv" onClick={handleClick}>
                  <video autoPlay muted loop ref={videoRef} src={clientvideo}></video>
                  <div className='playicon'>
                    {paused ? <BsPlay /> : <BsPause />}
                  </div>
                </div>




              </div>
            </SwiperSlide> */}


            

          </Swiper>


        </div>


      </div>
    </div>
  )
}