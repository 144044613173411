import React, { useEffect } from 'react';
import AOS from 'aos';
import './Firstsection.css'

import logo from '../../../Assets/Img/logo.svg'
import Navbar from '../Navbar/Navbar'
import Problemsolution from '../Problemsolution/Problemsolution'
import Ourtalent from '../OurTalent/Ourtalent'
import Talentstack from '../Talentstack/Talentstack'

import 'aos/dist/aos.css'; // Import AOS styles
import video from '../../../Assets/videos/video.mp4'
import Step from '../Steps/Step';
import Process from '../Process/Process';
import Guarantee from '../Guarantee/Guarantee';
import Ourclient from '../OurClient/Ourclient';
import Whyus from '../WhyUs/Whyus';
import Cta from '../Cta/Cta';
export default function Firstsection() {


  useEffect(() => {
    AOS.init();
  }, []); // Run the initialization only once when the component mounts


  return (
    <div>

      <div className="bgpic">

        <video src={video} autoPlay muted loop></video>


        <div data-aos="fade-up" className='maindiv'>

          <img className='collwaysimg' src={logo} alt="" />
          <h1>Collab Ways</h1>
        </div>

        <div className="headingandparagraphmaindiv">

          <div data-aos="fade-up" data-aos-duration="1200">
            <h1>
              Hire Remote
              <br />
              Top Tier Talent
            </h1>

          </div>

          <div data-aos="fade-left" data-aos-duration="1500">
            <p>
            <b>CollabWays</b>  offers a unique 
            <br />
             combination of <b> top-tier remote </b>
             <br />
             talent sourced through a rigorous
             <br />
              vetting process to  <b>reduce</b>  salary and 
              <br />
               operational cost.
            </p>


            <a target="_blank" rel="noopener noreferrer"  href="https://calendly.com/jamal-massab/catch-up">
              <button data-aos="zoom-in" data-aos-duration="1800">Book a meeting
                <span><svg className='arrowbtnicon' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                  <path d="M18.5 1.5C18.5 0.947716 18.0523 0.5 17.5 0.5L8.5 0.500001C7.94771 0.5 7.5 0.947716 7.5 1.5C7.5 2.05229 7.94772 2.5 8.5 2.5L16.5 2.5L16.5 10.5C16.5 11.0523 16.9477 11.5 17.5 11.5C18.0523 11.5 18.5 11.0523 18.5 10.5L18.5 1.5ZM2.20711 18.2071L18.2071 2.20711L16.7929 0.792894L0.792893 16.7929L2.20711 18.2071Z" fill="#FF471A" />
                </svg></span>
              </button>
            </a>

          </div>
        </div>


        {/* navbar */}


        <div data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-duration="1000" className='navbarposi' >

          <Navbar />

        </div>

      </div>

      {/* Problem solution */}

      <Problemsolution />

      {/* our talent */}

      <Ourtalent />

      <Talentstack />

      <Step />

      <Process />

      <Whyus />

      <Guarantee />


      <Ourclient />

      <Cta />

    </div>
  )
}
