import React from 'react'
import './Talentstack.css'

export default function Talentstack() {
  return (
    <div>
      <div data-aos="fade-up"  data-aos-duration="1500" className="talentstackdiv">
        <h1>Explore Our Talent</h1>

        <div className='marquee-container'>

          <div className='allskillsdiv addanimation1'>
            <p>Node js</p>
            <p>Express js</p>
            <p>Python</p>
            <p>MongoDB</p>
          </div>

        </div>

        <div className="bottomlinehr">

          <div className='marquee-container'>

            <div className='allskillsdiv addanimation2'>
              <p>UI Design</p>
              <p>UX Design</p>
              <p>Graphics  Design</p>
              <p>Brand Design</p>
            </div>

          </div>

        </div>

        <div className="bottomlinehr">

          <div className='marquee-container '>

            <div className='allskillsdiv addanimation03'>
              <p>Vue.JS</p>
              <p>React.Js</p>
              <p>Bootstrap</p>
              <p>Angular.JS</p>
            </div>

          </div>
        </div>

      </div>

    </div>
  )
}
