import React, { useState } from 'react'


// import rightarrow from '../../../../Assets/Img/rightarrow.svg'
import rizwanpic from '../../../../Assets/Img/rizwanpic.svg'
import Navbar from '../../Navbar/Navbar'
import rectangle from '../../../../Assets/Img/rectangle.svg'

export default function Rizwan() {

  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="faizanprofilemaindiv">

        <div className='mainflex'>



          <div className='maincontent'>


            <h1>Rizwan Sadiq</h1>
            <h2>Video Editor</h2>
            <h3>8 Years</h3>

            <h4>Skillset:</h4>

            <div className='mainskillsetdiv'>

              <div className='skilldiv'>
                <h5>Video Editor</h5>
              </div>

              <div className='skilldiv'>
                <h5>
                  Branding & Identity</h5>
              </div>

              <div className='skilldiv'>
                <h5>
                  Graphics</h5>
              </div>

              <div className='skilldiv'>
                <button onClick={openModal} >See All</button>

                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>

                      <div className="theskills">
                        <h1>Rizwan Skillset</h1>

                        <div className='marquee-container skillsmaindivslide'>

                          <div className='allskillsdiv'>
                            <p>Video Editor</p>
                            <p>
                              Design</p>
                            <p>
                              Digital Art</p>
                            <p>Branding & Identity</p>
                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>
                                Videography</p>
                              <p>
                                Visual Effects</p>
                              <p>
                                Photography</p>

                            </div>

                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>
                                Information Technology</p>
                              <p>
                                Art</p>
                              <p>
                                Graphics</p>

                            </div>

                          </div>

                        </div>



                      </div>
                    </div>
                  </div>
                )}
              </div>


            </div>

            <br /><br />

            <p id='maincontentparagraph'>I'm Rizwan, your go-to creative ninja with 8 years of design and video wizardry under my belt. If you want to turn your ideas into something mind-blowing, I'm your guy!
            </p>

          </div>



          <div className='faizanimg'>
            <img src={rizwanpic} alt="" />
          </div>

        </div>


        <div className="skillexperiencediv">

          <div className="processmaindiv">
            <h1 className='experienceheading' >Experience</h1>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Creative Lead</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Creative Lead</h2>
                <p>
                  Creative / Concept Manager
                  <br />
                  Digital Street · Full-time
                  <br />
                  Oct 2021 - Present · 2 yrs 5 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Creative Lead </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Dataspecc</h2>
                <p>
                  Creative / Concept Manager
                  <br />
                  Full-time · 1 yr 2 mos <br />

                  Jun 2020 - Apr 2022 · 1 yr 11 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Information Technology Team Lead </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Concept Manager</h2>
                <p>
                  Creative / Concept Manager
                  <br />
                  Full-time <br />
                  Oct 2020 - Oct 2021 · 1 yr 1 mo <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>



          </div>
        </div>





        <div className='navbarposi'>
          <Navbar />
        </div>
      </div>

    </div>
  )
}