import React from 'react'
import './Problemsolution.css'

export default function Problemsolution() {
  return (
    <div>

        <div className="problemsolutionmaindiv">

          <div className='problemcontent'>
              <h1>Problem</h1>
              <h2>Businesses struggle to find top-tier talent within their budget.</h2>
          </div>

          <div className='solutionmaindiv'> 
          <h2>Solution</h2>
            <h1>CollabWays combines   <b>top-tier talent </b> with  <b>cost-effective solutions</b>,
               enabling businesses to achieve excellence without exceeding their budgets. 
               Experience premium service at an affordable price with CollabWays.</h1>
          </div>

        </div>



    </div>
  )
}
