import React, { useEffect } from 'react';
import './Step.css'

import AOS from 'aos';

export default function Step() {

  useEffect(() => {
    AOS.init();
  }, []); // Run the initialization only once when the component mounts


  return (
    <div>

      <div className="stepmaindiv" >

        <div>

          <div id='step1maindiv' className='stepandhiremaindiv'>
            <div data-aos="fade-up" data-aos-duration="1000">  Steps</div>
            <div data-aos="fade-up" data-aos-duration="1000">to </div>
          </div>
          <br />
          <div className='stepandhiremaindiv'>
            <div data-aos="fade-up" data-aos-duration="1000">  Hire </div>
            <div data-aos="fade-up" data-aos-duration="2000">  Talent    </div>
          </div>


        </div>

        <div data-aos="zoom-in-up" data-aos-duration="1500" className="mainstepdiv">

          <div className='stepcards'>
            <h2>Tell us what <br />
              you need</h2>
            <p>Let us know your needs, whether it's an individual or an entire team, we're here to help! A consultant will reach out to you to understand your requirements better prior to sourcing your talent.</p>

          </div>

          <div className='stepcards seccard'>
            <h2>Find your  <br />
              prefect fit</h2>
            <p>You'll be presented with shortlisted candidates from our highly vetted talent pool within 15 to 48 hours. You may even arrange a call with the shortlisted talents & determine if it's the right fit.</p>

          </div>

          <div className='stepcards thirdcard'>
            <h2>Start a Risk <br />
              Free Trail</h2>
            <p>We even offer a 7-Day No-Risk trial for each hire, so you can replace your talent or get a full refund if you aren't 100% convinced!.</p>

          </div>

        </div>

      </div>

    </div>
  )
}
