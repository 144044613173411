import React, { useState } from 'react';
import './Cta.css'

export default function Cta() {

    const [copiedText, setCopiedText] = useState('');


    const copyToClipboard = () => {
        const buttonText = "team@collabways.com";
        navigator.clipboard.writeText(buttonText)
            .then(() => {
                setCopiedText(buttonText);
                setTimeout(() => {
                    setCopiedText('');
                }, 1000);
            })
            .catch((error) => {
                console.error('Failed to copy: ', error);
            });
    };

    return (
        <div>
            <div id='letstart' className="ctamaindiv">


                <div className="mainflexdivcta">

                    <div className="ctafstwhitediv">
                        <p>If you are looking to hire Remote Resources </p>
                        <h1>Let’s
                            <br />
                            Start</h1>

                            

                        <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSesYUmjlvNRXojr-SY7Gkc1mioSZq-gzEpxvNJojP_P4utysA/viewform">
                            <button>Tell us your requirements</button>
                        </a>

                        <h2>OR</h2>

                        <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/jamal-massab/catch-up">
                            <button>Book a meeting</button>
                        </a>

                    </div>


                    <div className="ctasecwhitediv">
                        <p>If you are looking for Job </p>
                        <h1>
                            Join
                            <br />
                            Us
                        </h1>

                        <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/1a5OPJvKKXQZgjkM178KCtE2pFCMUYTaNNmHsXRBOO2E/viewform?edit_requested=true">
                            <button>
                                Tell us about yourself
                            </button>
                        </a>

                        <h2>OR</h2>

                        <div className='copytextmain'>
                            {copiedText && (
                                <div
                                    className='copytextdivp' >
                                    Copied
                                </div>
                            )}
                            <button id="copyButton" onClick={copyToClipboard}>
                                Email us at team@collabways.com
                            </button>
                        </div>

                    </div>




                </div>

            </div>
        </div>
    )
}
