import React, { useState } from 'react'
import './Ahmed.css'

// import rightarrow from '../../../../Assets/Img/rightarrow.svg'
import ahmedpic from '../../../../Assets/Img/ahmedpic.svg'
import Navbar from '../../Navbar/Navbar'
import rectangle from '../../../../Assets/Img/rectangle.svg'

export default function Ahmed() {


  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="faizanprofilemaindiv">

        <div className='mainflex'>



          <div className='maincontent'>


            <h1>Ahmed Hamza</h1>
            <h2>Creative UI/UX Designer</h2>
            <h3>6 Years</h3>

            <h4>Skillset:</h4>

            <div className='mainskillsetdiv'>

              <div className='skilldiv'>
                <h5>Flutter</h5>
              </div>

              <div className='skilldiv'>
                <h5>React Native</h5>
              </div>

              <div className='skilldiv'>
                <h5>Android</h5>
              </div>

              <div className='skilldiv'>
                <button onClick={openModal} >See All</button>

                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>

                      <div className="theskills">
                        <h1>Kaleem Hamza Skillset</h1>

                        <div className='marquee-container skillsmaindivslide'>

                          <div className='allskillsdiv'>
                            <p>Problem Solving</p>
                            <p>UI UX Designing</p>
                            <p>Graphic Design</p>

                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>Web Design</p>
                              <p>Mobile Application Design</p>

                            </div>

                          </div>

                        </div>


                      </div>
                    </div>
                  </div>
                )}
              </div>


            </div>


            <br /><br />


            <p id='maincontentparagraph'>Creative UI/UX Designer with a demonstrated history of crafting visually stunning and user-centric digital experiences. Proven expertise in translating conceptual ideas into compelling design concepts, wireframes, and interactive prototypes. Adept at combining artistic flair with a deep understanding of user behavior to create intuitive and engaging interfaces. Skilled in collaborating with cross-functional teams, including developers and product managers, to ensure seamless integration of design elements into the final product.</p>
          </div>



          <div className='faizanimg'>
            <img src={ahmedpic} alt="" />
          </div>

        </div>


        {/* the experience div is here */}
        <div className="skillexperiencediv">

          <div className="processmaindiv">
            <h1 className='experienceheading' >Experience</h1>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>RepCard</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>UI UX Designer</h2>
                <p>
                  RepCard.Official
                  <br />
                  Full-time
                  <br />
                  Apr 2023 - Present · 11 mos
                  <br />
                  United States.
                  <br />
                  Remote
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>RZ Technologies</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Senior User Interface Designer</h2>
                <p>
                  RZ Technologies.Official
                  <br />
                  Full-time
                  <br />
                  Jan 2020 - Sep 2023 · 3 yrs 9 mos
                  <br />
                  Kohat District, Khyber Pakhtunkhwa, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Senda Japan</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Graphics and UI Designer</h2>
                <p>
                  Senda Japan.Official
                  <br />
                  Part-time
                  <br />
                  Aug 2017 - Dec 2019 · 2 yrs 5 mos
                  <br />
                  Tokyo, Japan
                </p>
              </div>
            </div>



          </div>
        </div>

        {/* the experience div is here */}

        <div className='navbarposi'>
          <Navbar />
        </div>
      </div>

    </div>
  )
}
