import React, { useState } from 'react'
import './Faizan.css'

import faizanpic from '../../../../Assets/Img/faizanpic.svg'
import Navbar from '../../Navbar/Navbar'
import rectangle from '../../../../Assets/Img/rectangle.svg'

export default function Faizan() {

  const [isModalOpen, setIsModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="faizanprofilemaindiv">

        <div className='mainflex'>



          <div className='maincontent'>


            <h1>Faizan Khan</h1>
            <h2>Full Stack /MERN Stack Developer</h2>
            <h3>6 Years</h3>

            <h4>Skillset:</h4>

            <div className='mainskillsetdiv'>

              <div className='skilldiv'>
                <h5>Node Js</h5>
              </div>

              <div className='skilldiv'>
                <h5>Express Js</h5>
              </div>

              <div className='skilldiv'>
                <h5>React Js</h5>
              </div>

              <div className='skilldiv'>
                <button onClick={openModal} >See All</button>

                {isModalOpen && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>

                      <div className="theskills">
                        <h1>Faizan Skillset</h1>

                        <div className='marquee-container skillsmaindivslide'>

                          <div className='allskillsdiv'>
                            <p>Node js</p>
                            <p>Express js</p>
                            <p>Python</p>
                            <p>MongoDB</p>
                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container'>

                            <div className='allskillsdiv'>
                              <p>UI Design</p>
                              <p>UX Design</p>
                              <p>Graphics  Design</p>
                              <p>Brand Design</p>
                            </div>

                          </div>

                        </div>

                        <div className="bottomlinehr">

                          <div className='marquee-container '>

                            <div className='allskillsdiv'>
                              <p>Vue.JS</p>
                              <p>React.Js</p>
                              <p>Bootstrap</p>
                              <p>Angular.JS</p>
                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>


            </div>


            <br /><br />


            <p id='maincontentparagraph'>Experienced Full Stack/MERN Stack Developer with a demonstrated history of designing and implementing innovative and scalable IT solutions. Proficient in the entire software development life cycle, from concept to delivery, with a strong focus on creating robust and efficient applications. Skilled in both front-end and back-end technologies, adept at developing responsive and User-friendly Interfaces. </p>


          </div>



          <div className='faizanimg'>
            <img src={faizanpic} alt="" />
          </div>

        </div>




        <div className="skillexperiencediv">

          <div className="processmaindiv">
            <h1 className='experienceheading' >Experience</h1>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Nisum</h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Senior Software Engineer</h2>
                <p>
                  Nisum.Official
                  <br />
                  Full-time
                  <br />
                  Apr 2022 - Present · 1 yr 11 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Evamp & Saanga </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Senior Software Engineer</h2>
                <p>
                  Evamp & Saanga
                  <br />
                  Full-time <br />

                  Jun 2020 - Apr 2022 · 1 yr 11 mos
                  <br />
                  Islāmābād, Pakistan
                </p>
              </div>
            </div>

            <div className="bottomlinehr"></div>

            <div className="mainflexdiv">
              <div className='recandh3maindiv'>
                <img src={rectangle} alt="" />
                <h3>Information Technology Team Lead </h3>
              </div>

              <div className="experiencecontentmaindiv">
                <h2>Senior Software Engineer</h2>
                <p>
                  Provoxio Pvt Ltd
                  <br />
                  Full-time <br />
                  Jun 2018 - May 2019 · 1 yr <br />
                  Northern Punjab Rawalpindi, Pakistan
                </p>
              </div>
            </div>



          </div>
        </div>






        <div className='navbarposi'>
          <Navbar />
        </div>
      </div>

    </div>
  )
}