import React from 'react'
import './App.css'

// react Rounting
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Firstsection from './Components/LandingPage/Firstsection/Firstsection';
import Faizan from './Components/LandingPage/AllProfiles/FaizanProfile/Faizan';
import Juanid from './Components/LandingPage/AllProfiles/JuanidProfile/Juanid';
import Ahmed from './Components/LandingPage/AllProfiles/Ahmedprofile/Ahmed';
import SyedBanoori from './Components/LandingPage/AllProfiles/SyedBanooriProfile/SyedBanoori';
import Ziakhan from './Components/LandingPage/AllProfiles/Ziakhanprofile/Ziakhan';
import Fazalrehman from './Components/LandingPage/AllProfiles/Fazalprofile/FazalProfile';
import FazalProfile from './Components/LandingPage/AllProfiles/Fazalprofile/FazalProfile';
import Rizwan from './Components/LandingPage/AllProfiles/RizwanProfile/Rizwan';


// import Navbar from './Components/LandingPage/Navbar/Navbar';
//    <Route path='/navbar' element={<Navbar />} /> */}

export default function App() {
  return (
    <div>

<Router>
  
        <Routes>
          <Route path='/' element={<Firstsection />} />
          <Route path='/faizan' element={<Faizan />} />
          <Route path='/junaid' element={<Juanid />} />
          <Route path='/ahmed' element={<Ahmed />} />
          <Route path='/fazal' element={<FazalProfile />} />
          <Route path='/rizwan' element={<Rizwan />} />
          <Route path='/syedbanoori' element={<SyedBanoori />} />
          <Route path='/zia' element={<Ziakhan />} />
          <Route path='/fazal' element={<Fazalrehman />} />
        </Routes>
      </Router>
      

    </div>
  )
}
